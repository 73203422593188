.navbar-vertical .navbar-nav {
  .nav-divider {
    border: 1px solid #EDECF1;
  }

  .nav-item {
    text-transform: capitalize;
    .nav-link {
      color: #ffffff;

      &.disabled {
        opacity: 0.4;
      }

      &.hidden {
        display: none;
      }
    }
  }
}

.bg-blue {
  background-color: #1B4298;
}

.border-blue {
  border-color: #1B4298;
}

.text-blue {
  color: #1B4298;
}