.profile {

  .container-xl {
    padding: 0 2.45rem;
  }

  .card-tabs {
    &_title {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75rem;
      margin-bottom: 0.85rem;
    }

    &_link {
      font-weight: 500;
      color: #000000;

      &.active {
        color: #754FFE;
        background-color: #E3DCFF;
      }


      i {
        font-size: 1rem;
        color: #BDB9C9;
      }
    }
  }

  .card-profile {
    &_avatar {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      &-text {
        text-align: center;
      }
    }
  }
}

@media (min-width: 576px) {
  .profile {
    .card-profile {
      &_avatar {
        flex-direction: row;

        &-text {
          text-align: left;
        }

        &-buttons {
          margin-left: auto;
        }
      }
    }
  }
}