.checked-item {
  position: relative;
  height: 2.25rem;
  flex-basis: 50%;
  padding-left: 1.75rem;
  margin-bottom: 1rem;

  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;

  >svg {
    width: 1.25rem;
    color: #19CB98;
    position: absolute;
    top: 0.15rem;
    left: 0rem;
  }
}