.star-rating {
  font-size: 1rem;
  line-height: 1;
  background-color: #79758f;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.star-rating.full {
  background-color: #ffaa46;
}

.star-rating.half {
  background: linear-gradient(270deg, #79758f 50%, #ffaa46 50%);
  background-clip: text;
  -webkit-background-clip: text;
}