p,
h1,
h2,
h3,
h4,
h5 {
  color: #635E7C;
}

.text-gray {
  color: #BDB9C9;
}

.follow-button {
  color: #AFA9B4;
  border-color: #E8E7ED;
}