.lesson {
  .card-info {
    margin-bottom: 1.75rem;

    .card-header {
      border: 0;
    }

    .card-footer {
      padding: 0 0.5rem;
    }

    &-title {
      display: flex;
      align-items: center;

      h1 {
        font-size: 1.85rem;
        font-weight: 600;
        color: #000000;
        margin-right: auto;
      }
    }

    &-description {
      display: flex;
      gap: 1.4rem;

      >span {
        display: inline-flex;
        align-items: center;
      }
    }

    .nav-item .lesson-nav-link.nav-link {
      padding: 1.35rem 0;
      border-top-width: 2px;
    }
  }

  .card-description {
    .learn-list {
      position: relative;
      padding: 0;
      max-height: 350px;
      display: flex;
      flex-wrap: wrap;
      color: #BDB9C9;
      list-style: none;
    }
  }

  .file-list_item {
    display: flex;
    align-items: center;
    gap: 1.5rem;

    &-icon {
      border-radius: 0.5rem;
      width: 3.35rem;
      height: 3.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 1.85rem;
      }
    }

    &-title {
      flex-basis: 35%;
    }

    &-size {
      width: 60px;
    }
  }

  .list-ranking {
    flex: 5;
    list-style: none;
    padding: 0;
  }
}

.fs-6-5 {
  font-size: 1.15rem;
}

.player {
  padding: 1.5rem 0;

  .player-wrapper {
    position: relative;
    padding-top: 56%;
    border-radius: 1rem;
    overflow: hidden;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
}


.lessons-list {
  &_item {
    padding: 0;

    .tasks-list {
      &_item {
        display: flex;
        align-items: center;
        padding: 0.75rem 1.25rem;
        color: #635E7C;

        .progress {
          height: 0.375rem;
        }

        .timer {
          color: #BDB9C9;
          margin-bottom: 0;
          margin-left: auto;
          font-weight: 500;
        }

        &.active {
          background-color: #1B4298;
          color: #FFFFFF;

          >* {
            color: #FFFFFF;
          }
        }
      }
    }
  }
}

.btn-play {
  background: #F5F4F8;

  >* {
    color: #754FFE;
    font-size: 1.15rem;
  }

  &.blocked>* {
    color: #BBB7C8;
    font-size: 1.35rem;
  }

  &.finished {
    background: #19CB98;

    >* {
      color: #ffffff;
    }

    &:hover {
      background: #19CB98;
    }
  }

  &:hover {
    background: #F5F4F8;
  }
}

@media (min-width: 1200px) {
  .lesson {
    .row {
      padding-right: 4rem;
    }

    .card-description {
      &_pane-avaliations {
        padding-right: 118px;
      }
    }
  }
}