.fs-7 {
  font-size: 0.65rem;
}

.section-list {
  padding: 40px 0;
  background: linear-gradient(90deg, #D70378 0%, #1B4298 100%);
}

.courses-sort {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.courses-list {
  display: flex;
  flex-direction: column;

  .loading-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .courses-list {
    display: flex;
    flex-direction: column;

    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -0.5rem;

    &_item {
      flex-basis: 50%;
      padding: 0.5rem;
    }
  }

  .filters-col {
    max-width: 292px;
  }
}

@media (min-width: 1200px) {

  .courses-list,
  .courses-sort {
    padding-right: 148px;
  }
}