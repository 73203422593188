#db-wrapper.toggled .header {
  width: calc(100% - 250px);
  min-width: 220px;
}


.header {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  transition: width 0.25s ease-out;

  .navbar {
    padding: 0.625rem 0rem;
  }
}

@media (min-width: 992px) {
  .header {
    .search-item {
      padding-left: 90px;
    }

    .navbar-right-wrap {
      max-width: 390px, ;
      padding-right: 66px;
    }
  }
}

@media (min-width: 768px) {
  .container-xl {
    padding: 0 60px;
  }

  .header {
    width: calc(100% - 250px);
  }

  #db-wrapper.toggled .header {
    width: 100%;
  }
}