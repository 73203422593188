.account {
  box-shadow: 0px 7px 12px #0000001A;
  max-width: 465px;
  margin: 15px;

  &-logo {
    text-align: center;
    user-select: none;

    img {
      width: 100%;
      max-width: 290px;
    }
  }

  .card-body {
    padding: 1.5rem 2.5rem;
  }
}

@media (max-width: 425px) {
  .account .card-body .display-5 {
    font-size: 1.5rem;
  }
}