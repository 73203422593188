.profileHeader {
  border-radius: 0.75rem;
  overflow: hidden;

  &-banner {
    height: 124px;
    background: linear-gradient(90deg, #D70378 0%, #1B4298 100%);
  }


  &-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    background-color: #ffffff;
    padding: 0.75rem 1.65rem 1.65rem;
  }

  &-button {
    flex-shrink: 0;
  }

  &-text {
    text-align: center;
  }

  &-avatar {
    position: relative;
    width: 5rem;
    height: 1.75rem;
    flex-shrink: 0;

    img {
      position: absolute;
      bottom: 0;
    }
  }
}


@media (min-width: 1200px) {}

@media (min-width: 576px) {
  .profileHeader {

    &-content {
      flex-direction: row;
    }

    &-button {
      flex-shrink: 0;
    }

    &-text {
      margin-right: auto;
      text-align: left;
    }
  }
}