#category-slider {
  max-width: 1080px;
  padding: 0 120px;
  margin: 0 auto;

  .slick-slider {
    width: 100%;
  }

  .item {
    padding: 0.5rem;

    &-no-slider {
      max-width: 280px;
    }
  }

  @media (max-width: 1400px) {
    padding: 0 100px;
  }

  @media (max-width: 1200px) {
    padding: 0 80px;
  }


  @media (max-width: 992px) {
    padding: 0 40px;
  }

  @media (max-width: 576px) {
    padding: 0 20px;
  }
}

.section-home {
  min-width: 1000px;
  padding: 135px 0;
  background-image: url('../../assets/images/organon/background_home.png');
  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: cover;

  .home-text {
    max-width: 386px;
  }
}


@media (min-width: 576px) {
  .section-home .home-text {
    max-width: 416px;
    padding-left: 30px;
  }
}

@media (min-width: 992px) {
  .section-home .home-text {
    max-width: 475px;
    padding-left: 88px;
  }
}