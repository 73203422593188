.footer-container {
  .footer-content {
    display: flex;
    gap: 0.85rem;
    padding-top: 1rem;
    padding-bottom: 2rem;

    font-weight: medium;
    font-size: 0.85rem;
    letter-spacing: 0px;

    border-top: 1px solid #EDECF1;
    color: #635E7C;
  }
}

@media (min-width: 1200px) {
  .footer-container .footer-content {
    margin-right: 66px;
  }
}