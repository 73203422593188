.alerts {
  .table> :not(caption)>*>* {
    padding: 0.75rem;
  }

  .table {
    tr {
      td {
        padding-top: 0.675rem;
        padding-bottom: 0.675rem;
      }
    }
  }
}


@media (min-width: 992px) {
  .alerts .container-xl {
    >.row {
      padding-right: 7rem;
    }
  }
}