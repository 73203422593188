/* Theme: Geeks */

// Inter Fonts
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

// Bootstrap functions
@import "../../../node_modules/bootstrap/scss/functions";

// User Variables
@import "user-variables";

// theme variables
@import "theme/variables";

@import "theme/utilities";

// Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap";

// Theme
@import "theme/theme";

// User
@import "user";

//Date Picker
@import "react-datepicker/dist/react-datepicker.css";
