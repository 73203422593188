.course-banner {
  height: 486px;
  background: linear-gradient(90deg, #D70378 0%, #1B4298 100%);
}

.course {
  .container-xl {
    >.row {
      margin-bottom: 5rem;
    }
  }
}

.related-section {
  .related-list {
    display: flex;
    flex-direction: column;
    gap: 1.35rem;

    >* {
      flex: 1;
    }
  }
}

.course-intro {
  .right-column {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    >* {
      flex: 1;
      margin-bottom: 1.5rem;
    }

    .material-icons {
      font-size: 1rem;
      vertical-align: middle;
    }
  }

  .left-column {
    &_title {
      max-width: 520px;
      padding: 3.25rem 0 4.05rem 0;

      &-stats {
        display: flex;
        gap: 0.6rem;
        flex-wrap: wrap;
        color: #ffffff;
        align-items: center;

        .mark-button {
          cursor: pointer;
          color: #FFFFFF;
        }

        >span {
          display: inline-flex;
          align-items: center;
        }
      }

      &-description {
        color: #ffffff;

        * {
          color: #ffffff;
        }
      }
    }

    .card {
      &-header {
        padding: 0 0.4rem;

        .nav-item {
          padding: 0 1.2rem;

          .nav-link {
            padding: 1.2rem 0;
            border-bottom-width: 2px;
          }
        }
      }

      &-body {
        .panel {
          margin: 25px;
        }

        .list-group-item {
          padding: 1rem 1.65rem;
          border-bottom-width: 1px;

          a {
            line-height: 20px;
          }
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .course-intro {
    .right-column {
      flex-direction: row;
    }
  }

  .course-banner {
    height: 398px;
  }
}

@media (min-width: 768px) {
  .course-intro {
    .left-column {
      &_title {
        &-stats {
          gap: 1.4rem;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .course-intro {
    padding-right: 46px;

    .right-column {
      display: block;
      max-width: 310px;

      > :first-child {
        margin-top: 204px;
      }
    }
  }

  .related-section {
    padding-right: 48px;

    .related-list {
      > :last-child {
        padding-right: 10px;
      }
    }
  }

  .ratings-featured {
    margin-right: 118px !important;
  }
}

@media (min-width: 920px) {
  .related-section {
    .related-list {
      flex-direction: row;
    }
  }
}