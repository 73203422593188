.painel {
  .cards {

    .nav-link {
      color: #635E7C;
    }
  }
}

@media (min-width: 1200px) {
  .painel {
    .container-xl {
      >.row {
        padding-right: 132px;
        padding-left: 2rem;
      }
    }
  }
}