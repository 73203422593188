.card {
  .text-truncate-line-2 {
    height: 3em;
  }

  .mark-button {
    cursor: pointer;
    border: unset;
    background-color: unset;
    i:hover {
      color: #754ffe
    }
  }
}