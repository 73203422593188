.sigin-banner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  height: 50%;

  background: #F6F6F6;

  &-fitter {
    font-size: 0.625rem;
    width: 100%;
    height: 100%;
    position: relative;
  }

  &-item {
    position: absolute;
  }
}